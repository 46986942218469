/* RheoFalt */

.car-inner p{
	text-align: center;
	font-size: 28px;
	@media (max-width: 992px){
		font-size: 20px;
		}
}
.carousel-indicators {

    bottom: -25px !important;
    }

.no-underline{
	text-decoration: none !important;
}
.imageholder{
	width: 100%;
	height: 150px;
	float: left;
	background-size: cover;
	background-position: center center;
	@media (max-width: 992px){
		margin-bottom: 10px;
		width:30%;
		
		}
}
.products{
	
	position: relative;
	padding:10px 10px;
	width:100%;
	
	h2{
		text-transform: uppercase;
		margin-top: 0px !important;
		margin-bottom: 5px !important;
	}

}
	p.more{
		color:$rheo-color;
	}
.products a.overlay{
	position: absolute;
	width:100%;
	height:100%;
	background-color: transparent;
	left:0px;
	top:0px;
	z-index: 99;
}
.products a.overlay:hover{
	background-color: rgba(75, 159, 61, 0.1);
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
}


.hp-img-holder{
	width:220px;
	border: 1px solid $black-color;
	position: relative;
	overflow: hidden;
	padding: 10px 0px 10px 0px;

}
.inovation img{
	width:200px;
}
.hp-title-holder{
	border: 1px solid $black-color;
	position: relative;
	overflow: hidden;
	padding: 0px 10px 0px 10px;
	width:220px;
	margin-top:0px;
	background-color: $black-color;
	
	span{
			color:#fff;
			font-size: 12px;
			line-height: 30px;
		}
		}
.pos-rel{
	position: relative;
}
.over-hid{
	overflow: hidden;
}
#legal_links li{
	list-style: none;
}
.page_tab {
	position: relative;
	z-index: 999;
    height:53px;
    margin-top:-53px;
}

@media (max-width: 515px){
	.xs-pull-left{
		float:left !important;
		margin-top:10px;
	}
	}

.page_number{
    height: 53px;
    width: 53px;    
    background-color: #FFF;   
    p{
	   line-height: 57px;
	   font-size: 24px;
	   color:$black-color;
    }
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
}

.page_title.rheofalt {
    
    height: 53px;
    background-color:$rheo-color;
    padding:0px 10px;
    h2{
	    font-size: 26px;
	    line-height: 53px;
	    text-transform: uppercase;
	    margin-top:0px;
	    color:#fff;
	    font-weight: bold;
    }
}
.fl {
    float: left !important;
}
.round-links{
	@media (max-width: 767px){
		margin-bottom:50px;
		}
}
.round-links img{
	width:75%;
	@media (max-width: 767px){
		width:50%;
		}
}
.font-helv{
	    font-family: $font-family-sans-serif !important;
    }
.button-wit, .button-blauw, .button-zwart {
    /* font-weight: 700; */
    font-size: 14px;
    color: $rheo-color;
    /* font-family: "museo", sans-serif; */
    /* text-transform: uppercase; */
    padding: 5px 13px 5px 13px;
    border: 1px solid $rheo-color;
    display: inline-block;
    margin: 10px 0 0;
    outline: none;
    background: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.carousel .carousel-indicators li {
    background: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 50%;
}
.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000\9;
    background-color: rgba(0,0,0,0);
    border: 1px solid #fff;
    border-radius: 10px;
}
.carousel .carousel-indicators li, .carousel .carousel-indicators .active {
    margin: 11px 10px;
}
.carousel .carousel-indicators .active {
    margin-top: 10px;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.hr-page {    
    background: url('../img/rule.png') no-repeat top center;
    border: 0;
    height: 20px;
    margin-top: 0px!important;
}

.btn {
    background-color: $black-color;
    border-color: $black-color;
}

a{
	color:$rheo-color;
}
p a{
	color:$rheo-color;
	text-decoration: underline;
}
a:hover{
	color:#101010;
}
.hr-small {
    width: 38px;
    border-top: 1px solid #676767;
    margin-top: 13px;
    margin-bottom: 13px;
}

.text-main h2.title{
	margin-top:0px;
	font-size: 2em;
	font-weight: 300 !important;
}
.text-main h1.title{
	margin-bottom:0px;
}
.car-inner{
	height:180px;
	overflow: hidden;
}

.carousel-indicators .active{
	background-color: #fff !important;
}
/* end */


/* contact pagina */

.company-information .adres {
    margin-left: 28px;
}
.company-information .fa{
	width:30px;
}

/* end */

.cookie{
    position: fixed;
    z-index: 9999999999;
    left: 0;
    bottom: 0;
    right: 0;
    background: #c96f6f;
    color: white;
    padding: 10px 10px 10px 10px;
    display: none;
    overflow: hidden;
}
.cookie p{
    margin: 0;
    font-size: 13px;
    line-height: 1.4;
    float: left;
}
.cookie a.close_cookie{
    position: relative;
    float: right;
    color: white;
    padding: 5px 10px;
    background: rgba(0,0,0,.5);
}
blockquote{
    width: 100%;
    border-left: 2px solid $rheo-color;
    background: white;
    line-height: 1.6;
    font-size: 21px;
    font-family: $font-family-heading;
    font-style: italic;
    position: relative;
    text-transform: uppercase;
    color: $rheo-color;
    padding: 20px;
    padding-left: 70px;
}
blockquote::before{
    font-family: FontAwesome;
    content: "\f10d";
    color: $rheo-color;
    font-size: 21px;
    position: absolute;
    left: 20px;
    top: 20px;
}
blockquote::after{
  content: '';
}
.overflow-hidden{
    overflow: hidden;
}
.text-image.img-responsive{
    width: 100%;
}
@media (max-width: 991px){
    .push-top-mob{
        margin-top: 30px;
    }
}
.parallax-window{
    blockquote{
        width: 100%;
        border-left: 1px solid white;
        background: none;
        background: rgba(0,0,0,.1);
        line-height: 1.6;
        font-size: 21px;
        font-family: $font-family-heading;
        font-style: italic;
        position: relative;
        text-transform: uppercase;
        color: white;
        padding: 20px;
        padding-left: 70px;
    }
    blockquote::before{
        font-family: FontAwesome;
        content: "\f10d";
        color: white;
        font-size: 21px;
        position: absolute;
        left: 20px;
        top: 20px;
    }
}

.info-box{
    background: rgba($rheo-color,.1);
    padding: 5px 20px 20px;
}
.card.card-plain{
    h2.subtitle{
        margin-bottom: 15px;
    }
    &.card-border{
        border: 1px solid #eee;
        padding: 15px;
        h1,h2,.title,subtitle{
            margin-top: 10px;
        }
    }
    .card-body{
        padding: 0;
    }
    img.school-logo{
        width: 140px;
        max-width: 100%;
        display: block;
        border: 1px solid #eee;
        border-radius: 0;
    }
    p.card-text{
        padding: 15px 0;
        line-height: 1.6;
        a{
            color: $rheo-color;
            color: $black-color;
            font-size: $font-paragraph;
            font-size: 16px;
            font-weight: $font-weight-light;
            font-weight: 200;
            //line-height: $line-height-general;
            line-height: 1.5em;
            i{
                color: $rheo-color;
            }
        }
        a:hover{
            text-decoration: underline;
        }
    }
}

.accordion{
    .card.card-plain{
        margin-bottom: 0;
        .card-header{
            .btn-link{
                padding: 0px 0px;
            }
            .btn:after{
                font-family: FontAwesome;
                content: "\f106";
                color: $rheo-color;
                color: #66615B;
                font-size: 1.35em;
                position: absolute;
                right: 20px;
                top: 12px;
            }
            .btn.collapsed:after{
                content: "\f107";
            }
        }
        .card-body{
            padding: .75rem 1.25rem .2rem;
            border: 1px solid #eee;
            .card-contacts,
            .card-address{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}
[data-toggle="collapse"] .fa:after {
  //content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:after {
  //content: "\f13a";
}
.site{
    position: relative;
    z-index: 0;
    padding-top: 85px;
    h1, h2, h3, h4, h5, h6, button, .navbar a{
        font-family: $font-family-heading;
       
    }
    .hidden{
        display: none;
    }
    .bg-white{
        background: white;
    }
    .bg-gray{
        background: #eee;
    }
    .meesterbaan{
        padding-left: 10px;
        padding-right: 10px;
    }
    .members-list{
        margin: 20px 0;
        .member{
            //font-size: 16px;
            //margin-bottom: 10px;
            .member-name{
                display: inline-block;
                font-size: 19px;
                line-height: 1.5;
                color: $rheo-color;
                position: relative;
                .member-socials{
                    position: relative;
                    overflow: hidden;
                    top: 7px;
                    margin-left: 15px;
                    padding-right: 1px;
                    float: right;
                    line-height: 11px;
                    .member-social{
                        float: left;
                        margin-right: -1px;
                        position: relative;
                        font-size: 11px;
                        line-height: 11px;
                        padding: 2px 2px;
                        border-radius: 0;
                        display: inline-block;
                        color: $rheo-color;
                        border: 1px solid $rheo-color;
                        color: #999;
                        border: 1px solid #ddd;
                        &:hover{
                            background: $rheo-color;
                            border: 1px solid $rheo-color;
                            color: white;
                        }
                    }
                }
                //font-weight: bold;
            }
            .member-sub{
                display: block;
                font-size: .8em;
                font-style: italic;
                color: #777;
                line-height: 1.5;
            }
        }
    }
    .social-line-gray{
        border-top: 1px solid #eee;
    }
    .pb-page{
        padding-bottom: 40px;
    }
    &.transparent{
        background: transparent;
    }
    .container{
        //max-width: 970px;
    }
    .breadcrumbs-xl{}
    .breadcrumb{
        background: none;
    }
    .wrapper{
        background: transparent;
    }
    .card-description{
        padding-top: 10px;
        a{
            color: $rheo-color;
            text-decoration: underline;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    #main_nav li{
	    border-right: 1px solid #D8D8D8;
    }
    #main_nav li:first-child{
	    border-left: 1px solid #D8D8D8;
	  
    }
     #main_nav li:first-child{
	     padding-left:10px !important;
     }
    .navbar{
        box-shadow: none;
    }
  
    
    .navbar-brand{
        padding: 0;
        position: relative;
        height: 70px;
        .logo{
            top: 5px;
            position: absolute;
            max-height: 70px;
            @media (max-width: 575px) {
                left: 15px;
            }
        }
    }
    .navbar-toggler{
        @media (max-width: 575px) {
            position: relative;
            right: 15px;
        }
    }
    .navbar[class*="bg-"] .navbar-toggler .navbar-toggler-bar,
    .navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar{
        background: $rheo-color;
    }
    @media (max-width: 991px){
    .collapse.navbar-collapse{
        background-color: $rheo-color;
        border-top: none;
        border-left: 1px solid darken($rheo-color,10%);
        .navbar-nav{
            margin: 0;
            margin-left: auto !important;
            .nav-link{
                padding: 15px 8px;
                color: white !important;
                background: rgba(255,255,255,.05);
            }
            .nav-link:hover{
                color: white !important;
                background: rgba(255,255,255,.2);
            }
        }
    }
    .collapse.navbar-collapse::after{
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: $rheo-color;
        border-left: 0 solid red;
        display: block;
        content: "";
        z-index: 1;
    }
    }
    .logo_kleur{
        display: block;
    }
    .logo-wit{
        display: none;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        //padding: 30px 15px 0px;
    }
    .navbar.bg-default{
        //background: rgba(255,255,255,.9);
        .navbar-nav{
            .nav-link:not(.btn),
            .dropdown-item{
                color: $rheo-color;
            }
            .dropdown-item.active{
                color: white;
                background: $rheo-color;
            }
            .nav-item .nav-link{
                color: $black-color;
            }
            .nav-item .nav-link:hover,
            .nav-item.active .nav-link{
                color: $rheo-color;
                border-bottom: 0px solid $rheo-color;
            }
        }
    }
    .navbar.bg-default.navbar-transparent{
        .navbar-nav{
            .nav-link:not(.btn){
                color: white;
            }
            .dropdown-item.active{
                color: white;
                background: $rheo-color;
            }
        }
    }
    .navbar .navbar-nav .nav-item .nav-link{       
        padding: 15px 10px 15px 10px;
    }
    .navbar-transparent{
        .logo_kleur{
            display: none;
        }
        .logo-wit{
            display: block;
        }
    }
 
    .header-wrapper{
        height: 550px;
    }

    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 0px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .breadcrumb{
        margin-bottom: 0px;
        padding-left: 0px;
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 59.25% 0 0 0;
        //margin-top: 20px;
        //margin-bottom: 20px;
        overflow: hidden;
        //border-radius: 12px;
        //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        .zoom-child{
            //border-radius: 12px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
}
@mixin theme-mixing($color) {
    .list-group-item.active {
        background-color: $rheo-color;
        border-color: $rheo-color;
    }
    .dropdown-item{
        &.active{
            background-color: lighten($color,7%);
            color: white;
        }
    }
    .dropdown-item:hover{
        background-color: lighten($color,7%) !important;
        color: white !important;
    }
    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border: 2px solid $color;
      color: $color;
    }
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > a:active,
    .pagination > li.active > a,
    .pagination > li.active > span,
    .pagination > li.active > a:hover,
    .pagination > li.active > span:hover,
    .pagination > li.active > a:focus,
    .pagination > li.active > span:focus {
      background-color: $color;
      border-color: $color;
      color: #FFFFFF;
    }
    .section.section-page-color{
        background-color: lighten($brown-color, 7%);
        color: white;
        h3{
            margin-top: 20px;
        }
    }
}
body{
    &.color-default{
        @include theme-mixing($default-color);
    }
    &.color-primary{
        @include theme-mixing($primary-color);
    }
    &.color-info{
        @include theme-mixing($rheo-color);
    }
    &.color-success{
        @include theme-mixing($success-color);
    }
    &.color-warning{
        @include theme-mixing($warning-color);
    }
    &.color-danger{
        @include theme-mixing($danger-color);
    }
}
body{
    color: $black-color;
    font-family: $font-family-sans-serif;
    font-weight: normal;
    b, strong{
        //font-weight: 300;
    }
    p{
	    font-family: $font-family-sans-serif;
    }

    .home-intro{
        padding-top: 0px;
        padding-bottom: 0px;
        p{
            font-size: 19px;
        }
    }
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        //background-color: #f5593d;
        //border-color: #f5593d;
    }
    h1.title,
    h2.title,
    h3.title{
	    margin-top:0px !important;
        font-size: 2.4em;
        margin: 20px 0 15px;
        color: $black-color;
        text-transform: uppercase;
        font-weight: 700;
        //margin-top: 50px;
        @media (max-width: 575px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 1.6em;
        }
        @media (max-width: 991px){
            font-size: 1.8em;
        }
    }
    
    h1.subtitle,
    h2.subtitle,
    h3.subtitle{
        font-size: 1.425em;
        
        color: $rheo-color;
        color: $black-color;
        margin: 20px 0 20px;
        font-weight: 700;
    }
    .title-row{
        h1.title,
        h2.title,
        h3.title{
            margin-bottom: 40px;
        }
    }
    .title-news{
        margin-bottom: 30px !important;
    }
    .card{
        .card-description{
            margin-top: 0;
            .btn{
                margin-top: 20px;
            }
        }
    }
    .text-main{
        p{
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 15px;
            letter-spacing: normal;
            
        }
                img.text-image{
            border: 3px solid white;
            box-shadow: 0 0 5px rgba(0,0,0,.3);
            margin: 0px 0px 15px;
            width: 100%;
            //max-width: 380px;
            display: block;
            &.left{
                float: left;
                margin-right: 15px;
            }
            &.right{
                float: right;
                margin-left: 15px;
            }
        }
        .text-block{
            display: block;
            clear: both;
            h2{
                font-size: 24px;
                font-weight: 600;
                margin: 30px 0 15px;
                display: block;
            }
            @media(max-width: 576px){
                img:not(.img-responsive){
                    display: inline-block;
                    width: 60% !important;
                    margin-left: 20% !important;
                    margin-right: 20% !important;
                    height: auto !important;
                }
            }
        }
    }
    .intro{
        margin-bottom: 15px;
        p{
            font-size: 19px;
            color: $rheo-color;
            font-weight: 400;
        }
        ul,ol{
            padding: 10px 0 10px;
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 19px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .text-intro{
        p{
            @media (min-width: 576px){
                margin-top: 0;
            }
            font-weight: bold;
        }
    }
    .home-contact{
        .image-landscape{
            display: block;
            position: relative;
            padding: 75% 0 0 0;
            //margin-top: 20px;
            //margin-bottom: 20px;
            overflow: hidden;
            margin-top: 10px;
        }
        h2.title{
            margin-top: 0;
            padding-top: 0;
        }
    }
    $footer-box-shadow: 0px -1px 1px 1px rgba(0,0,0,0.12);
    $footer-color: $rheo-color;
    $footer-bg-color: white;
    .footer-default{
        //-webkit-box-shadow: $footer-box-shadow;
        //-moz-box-shadow: $footer-box-shadow;
        //box-shadow: $footer-box-shadow;
        background: lighten($rheo-color, 50%);
        background: white;
        border-top: 1px solid #eee;
        color: $footer-color;
        margin-top: 0;
        h4{
		    color: #48A138;
		    color: #414143;
		    margin: 27px 0 15px;
		    padding: 0;
		    font-size: 16px;
		    line-height: 1;
		    text-transform: uppercase;
        }
        
        &.footer-big{
            padding-bottom: 0px;
        }
        hr{
            color: $rheo-color;
            border-color: lighten($rheo-color,20%);
        }
        
        .footernav{
            padding: 0;
            margin: 0;
            .nav-link{
                padding: 0;
            }
        }
        .microdata{
            font-size: 16px;
            line-height: 26px;
            .microText{
                
                //font-family: $font-family-heading;
				font-family: "Helvetica Neue", Helvetica, sans-serif;
				font-size: 0.8em;
				font-weight: 400;
				-webkit-font-smoothing: antialiased;
				color: $black-color;
				letter-spacing: normal;

            }
        }
        .footer-brand{
            img{
                display: block;
                width: 100%;
                max-width: 180px;
            }
            margin-bottom: 4px;
        }
        .uppercase-links.stacked-links a,
        .nav-link{
            color: $footer-color;
        }
        .copyright{
            background: $black-color;
            color: white;
            margin: 0;
            font-weight: normal;
            font-size: 11px;
                margin-top: 25px;
            a{
                color: white;
                opacity: 1;
                font-weight: bold;
            }
        }
    }
    $img-link-padding: 25px;
    .img-link{
        position: relative;
        display: block;
        border: 1px solid $rheo-color;
        background-color: $rheo-color;

        border: 1px solid #eee;
        background-color: white;
        padding: 66.25% 0 0 0;
        .img-link-child{
            position: absolute;
            left: $img-link-padding;
            right: $img-link-padding;
            top: $img-link-padding;
            bottom: $img-link-padding;
            overflow: hidden;
            //width: 100%;
            //height: 100%;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .4s;
        }
        &:hover .img-link-child,
        &:focus .img-link-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
    .page-header .filter::after{
        opacity: .5;
    }
    #main-content{
        position: relative;
    }
    &.home{
        #main-content{
            //padding-left: 300px;
        }
        .single.active.nav-item{
            i.fa{
                color: white;
            }
        }
    }
    &.splash_active{
        overflow: hidden;
        height: 100vh;
    }
    .splash-buttons{
        left: 350px;
        right: 0;
        @media (min-width: 576px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        @media (max-width: 991px) {
            left: 0;
        }
    }
    .splash-link{
        margin-bottom: 15px;
    }
    #progressBar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 22px;
        background-color: #eee;
        z-index: 1040;
        color: white;
    }
    #progressBar div {
      height: 100%;
      text-align: right;
      padding: 0 10px;
      line-height: 22px; /* same as #progressBar height if we want text middle aligned */
      width: 0;
      background-color: $rheo-color;
      color: white;
      box-sizing: border-box;
    }
    $splash-height: 100vh;
    .splash{
        position: fixed;
        //top: -220%;
        //bottom: 120%;
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
        z-index: 1200;
        z-index: 1040;
        background: rgba(255,255,255,1);
        padding-left: 350px;
        border-radius: 5px;
        overflow-y: scroll;
        display: none;
        -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        -webkit-transition: all .4s ease-out;
        transition: all .4s ease-out;
        @media (max-width: 991px) {
            padding-left: 0px;
        }
        @media (max-width: 768px) {
            padding-top: 60px;
            padding-bottom: 60px;
            .card.card-plain.card-border{
                background: rgba(255,255,255,.95);
            }
        }
        &.active{
            //top: 5px;
            //bottom: 5px;
        }
        .btn-close-splash{
            position: absolute;
            right: 36px;
            top: 15px;
            color: $rheo-color;
            font-size: 32px;
        }
            .logo-vertical{
                position: absolute;
                @media (max-width: 768px) {
                    position: fixed;
                    z-index: 0;
                    opacity: .4;
                    top: 60px;
                }
                left: 0;
                right: 0;
                //top: 0;
                bottom: 0;
                padding: 0 30px;
                img{
                    display: inline-block;
                    //position: absolute;
                    //bottom: 0;
                    //left: 30px;
                    //right: 30px;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
    }
    .homeFeed{
        position: absolute;
        top: 5px;
        left: 5px;
        bottom:5px;
        width: 350px;
        z-index: 1020;
        padding: 15px;
        //margin-left: -15px;
        //margin-right: -15px;
        //padding-top: 1px;
        //padding-right: 1px;
        //padding-bottom: 1px;
        //padding-left: 1px;
        //height: $splash-height;
        //overflow-y: scroll;
        //overflow-x: visible;
        overflow: hidden;
        //background: $rheo-color;
        background: white;
        @media (max-width: 991px) {
            display: none;
        }
    }
    .feedItem{
        width: 48%;
        display: block;
        float: left;
        border: 5px solid white;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
            box-shadow: -1px -1px 4px 2px rgba(0,0,0,0.25);
        &.x2{
            //width: 50%;
        }
        img{
            display: inline-block;
            width: 100%;

        }
    }
    .img-responsive{
        max-width: 100%;
    }
}
.ekko-lightbox{
    .modal-dialog{
        background: none;
        .modal-content{
            background: none;
            border: 5px solid white;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            border-radius: 0;
            .modal-header.hide{
                padding: 0;
                margin: 0;
                height: 0 !important;
                border: 0;
                background: none;
                position: relative;
                .close{
                    position: absolute;
                    bottom: -55px;
                    right: 24px;
                    z-index: 1050;
                    font-size: 48px;
                    color: white;
                }
            }
            .modal-body{
                padding: 0;
                margin: 0;
            }
            .modal-footer.hide{
                padding: 0;
                margin: 0;
                height: 0;
            }
        }
    }
}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}
